import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { ValidationErrors } from 'types/errors';
import type { ScenarioGroup, Selector, SelectorCondition } from 'types/models';
import type { ModalFormData } from 'components/ModalForm';
import useApiRequest from 'hooks/useApiRequest';
import organizationStore from 'stores/Organization';
import apiSelectors from 'api/selectors';
import apiOrganization from 'api/organization';
import apiScenarios from 'api/scenarios';
import ModalForm from 'components/ModalForm';
import Form from './Form';

type Props = {
  onClose: () => void,
  edit?: boolean,
  defaultData?: Selector,
  group?: ScenarioGroup,
  onActionDone: (message: string) => void,
  onActionError: (message: string) => void,
};

const ModalSelector = (props: Props): JSX.Element => {
  const {
    onClose,
    edit = false,
    defaultData,
    onActionDone,
    onActionError,
    group,
  } = props;
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;
  const [conditions, setConditions] = useState<SelectorCondition[]>(defaultData?.conditions || []);
  const [hasConditionOpen, setHasConditionOpen] = useState<boolean>(false);
  const [errors, setErrors] = useState<ValidationErrors | undefined>();
  const { put, post } = useApiRequest();

  const handleSubmit = async (formData: ModalFormData | null) => {
    if (!currentOrganization || !formData || edit && !defaultData) {
      return;
    }

    if (!edit && !formData.name) {
      setErrors({ name: { code: 2, message: t('errors:validation.required-field') } });
      return;
    }

    if (conditions.length === 0) {
      onActionError(t('selectors:toast.error.required-conditions'));
      return;
    }

    if (hasConditionOpen) {
      onActionError(t('selectors:toast.error.validate-conditions'));
      return;
    }

    if (!formData.template) {
      setErrors({ template: { code: 2, message: t('errors:validation.required-field') } });
      return;
    }

    if (!formData.referenceDateOrigin) {
      setErrors({ referenceDateOrigin: { code: 2, message: t('errors:validation.required-field') } });
      return;
    }

    const request = edit ? put : post;
    const data = {
      identifier: formData.name,
      label: formData.name || defaultData?.label,
      sortOrder: parseInt(formData?.position as string, 10),
      dunningScenario: apiScenarios.resourceUrl(Number(formData.template as string)),
      organization: apiOrganization.resourceUrl(currentOrganization.id),
      referenceDateOrigin: formData.referenceDateOrigin,
      conditions,
    };
    const url = (edit && defaultData) ? apiSelectors.updateUrl(defaultData.id) : apiSelectors.createUrl;
    const result = await request(url, data);

    if (!result || result.errors) {
      setErrors(result?.errors);
      onActionError(t(edit ? 'selectors:toast.error.edit' : 'selectors:toast.error.add'));
      return;
    }

    onActionDone(t(
      edit ? 'selectors:toast.success.edit' : 'selectors:toast.success.add',
      { name: data.label },
    ));

    onClose();
  };

  const title = useMemo(() => (
    t(edit ? 'selectors:modal.edit' : 'selectors:modal.add', {
      group: group?.name ?? t('selectors:debt'),
    })
  ), [edit, t, group?.name]);

  return (
    <ModalForm
      isOpened
      onSave={handleSubmit}
      onCancel={onClose}
      title={title}
    >
      <Form
        edit={edit}
        defaultData={defaultData}
        conditions={conditions}
        onUpdateConditions={setConditions}
        onToggleConditionOpen={setHasConditionOpen}
        errors={errors}
        onActionError={onActionError}
        group={group}
      />
    </ModalForm>
  );
};

export default ModalSelector;
