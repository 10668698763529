import dayjs from 'dayjs';
import { uniq } from 'lodash';
import { FilterValue, SortingRule } from 'react-table';
import { SortDirection } from 'types/api';
import type { Collection, Filter, OperatorFiltering, SearchResult } from 'types/api';
import type Misc from 'types/misc';
import type {
  HistoryStepPreviewContent,
  Debit,
  Client,
  StepAction,
  Organization,
  HistoryStepActionbWebHook,
  ClientHistory,
  User,
  EavConstructor,
  HistoryStepConvertContent,
} from 'types/models';
import { EAVType, StepActionElasticSearchType, StepActionPeriodFilter, StepActionTypeFilter } from 'types/models';
import requester from 'utils/requester';

export enum StepActionStatus {
  VALIDATED = 'validated',
  COMPLETED = 'completed',
  CANCEL = 'cancelled',
}

export enum ActionCategory {
  FUTURE = 'future',
  DONE = 'done',
}

export type FetchActionsFutureAllParams = {
  page?: number | undefined,
  pageSize?: number,
  filtering?: Misc.Filter[],
  organizationReference: Organization['reference'] | undefined,
  locale: User['locale'] | undefined,
  attributes: EavConstructor[],
  sort?: SortingRule<StepAction>,
};

export type FetchActionsDoneAllParams = {
  page?: number | undefined,
  pageSize?: number,
  filtering?: Misc.Filter[],
  organizationReference: Organization['reference'] | undefined,
  locale: User['locale'] | undefined,
  attributes: EavConstructor[],
};

export type FetchAllHistoryForCustomerParams = {
  customerId: Client['id'] | undefined,
  page?: number,
  pageSize?: number,
  filtering?: Misc.Filter[],
};

export type FetchAllParam = {
  page?: number,
  pageSize?: number,
  filtering?: Misc.Filter[],
};

export type FetchAllForCustomerParams = {
  id: Client['id'] | undefined,
  params?: FetchAllParam | undefined
};

export type FetchAllForDebitParams = {
  id: Debit['id'] | undefined,
  params?: FetchAllParam | undefined
};

export type FetchPreviewParams = {
  code: StepAction['code'] | null,
  name?: StepAction['name'],
  description?: StepAction['description'],
  subject?: StepAction['subject'],
  content?: StepAction['content'],
};

/**
 * Récupère une liste de l'historique du client.
 *
 * @param id ID du client.
 * @returns Une collection historique.
 */
const allHistoryForCustomer = async (
  organization: Organization['id'] | undefined,
  params: FetchAllHistoryForCustomerParams | undefined,
): Promise<Collection<ClientHistory>> => {
  const { page, filtering, pageSize, customerId } = params ?? {
    page: 1,
    pageSize: 25,
    filtering: null,
    customerId: null,
  };

  if (!customerId) {
    throw new Error('AllHistoryForCustomer: Missing customer ID.');
  }

  const queryData = new URLSearchParams();
  queryData.append('page', (page ?? 1).toString());
  queryData.append('itemsPerPage', (pageSize ?? 25).toString());

  if (filtering && filtering.length > 0) {
    filtering.forEach(({ name, value }: Misc.Filter) => {
      queryData.append(name, (Array.isArray(value) ? value.join(',') : value) ?? '');
    });
  }

  const { data } = await requester.get<Collection<ClientHistory>>(
    `clients/${customerId}/history?${queryData.toString()}`,
  );
  return data;
};

/**
 * Récupère une liste des actions à venir du client.
 *
 * @param id ID du client.
 * @returns Une collection d'actions.
 */
const allFutureStepsForCustomer = async (
  { id }: FetchAllForCustomerParams,
): Promise<Collection<StepAction>> => {
  if (!id) {
    throw new Error('FetchFutureCustomerActions: Missing customer ID.');
  }

  const queryData = new URLSearchParams();
  queryData.append('itemsPerPage', '5');
  queryData.append('page', '1');

  const { data } = await requester.get<Collection<StepAction>>(
    `clients/${id}/actions/future?${queryData.toString()}`,
  );
  return data;
};

/**
 * Récupère une liste des actions passées du client.
 *
 * @param id ID du client.
 * @returns Une collection d'actions.
 */
const allPastStepsForCustomer = async (
  { id }: FetchAllForCustomerParams,
): Promise<Collection<StepAction>> => {
  if (!id) {
    throw new Error('FetchPastCustomerActions: Missing customer ID.');
  }

  const queryData = new URLSearchParams();
  queryData.append('itemsPerPage', '5');
  queryData.append('page', '1');

  const { data } = await requester.get<Collection<StepAction>>(
    `clients/${id}/actions/past?${queryData.toString()}`,
  );
  return data;
};

/**
 * Récupère une liste de l'historique du debit.
 *
 * @param id ID du debit.
 * @returns Une collection historique.
 */
const allHistoryForDebit = async (
  { id, params }: FetchAllForDebitParams,
): Promise<Collection<StepAction>> => {
  if (!id) {
    throw new Error('AllHistoryForDebit: Missing debit ID.');
  }

  const { page, filtering } = params ?? {
    page: 1,
    filtering: null,
  };

  const queryData = new URLSearchParams();
  queryData.append('page', (page ?? 1).toString());

  if (filtering && filtering.length > 0) {
    filtering.forEach(({ name, value }: Misc.Filter) => {
      queryData.append(name, (Array.isArray(value) ? value.join(',') : value) ?? '');
    });
  }

  const { data } = await requester.get<Collection<StepAction>>(
    `debits/${id}/history?${queryData.toString()}`,
  );
  return data;
};

/**
 * Récupère une liste des actions du debit.
 *
 * @param id ID du debit.
 * @param type Type d'action.
 * @returns Une collection d'actions.
 */
const allFutureStepsForDebit = async (
  { id, params }: FetchAllForDebitParams,
): Promise<Collection<StepAction>> => {
  if (!id) {
    throw new Error('allFutureStepsForDebit: Missing Debit ID.');
  }

  const { page, filtering } = params ?? {
    page: 1,
    filtering: null,
  };

  const queryData = new URLSearchParams();
  queryData.append('page', (page ?? 1).toString());
  queryData.append('itemsPerPage', '5');

  if (filtering && filtering.length > 0) {
    filtering.forEach(({ name, value }: Misc.Filter) => {
      queryData.append(name, (Array.isArray(value) ? value.join(',') : value) ?? '');
    });
  }

  const { data } = await requester.get<Collection<StepAction>>(
    `debits/${id}/actions/future?${queryData.toString()}`,
  );
  return data;
};

/**
 * Récupère une liste des actions du debit.
 *
 * @param id ID du debit.
 * @param type Type d'action.
 * @returns Une collection d'actions.
 */
const allPastStepsForDebit = async (
  { id, params }: FetchAllForDebitParams,
): Promise<Collection<StepAction>> => {
  if (!id) {
    throw new Error('allPastStepsForDebit: Missing Debit ID.');
  }

  const { page, filtering } = params ?? {
    page: 1,
    filtering: null,
  };

  const queryData = new URLSearchParams();
  queryData.append('page', (page ?? 1).toString());
  queryData.append('itemsPerPage', '5');

  if (filtering && filtering.length > 0) {
    filtering.forEach(({ name, value }: Misc.Filter) => {
      queryData.append(name, (Array.isArray(value) ? value.join(',') : value) ?? '');
    });
  }

  const { data } = await requester.get<Collection<StepAction>>(
    `debits/${id}/actions/past?${queryData.toString()}`,
  );
  return data;
};

const getMappingFromFormToApi = (name: string) => {
  const map = {
    include: 'full_text',
    exclude: 'full_text',
    manager: 'assigned_user',
    categories: 'business_units',
  } as Record<string, string>;

  return map[name] ?? name;
};

const getFilterValue = (name: string, value: FilterValue): Filter['value'] => {
  const currentValue = name !== 'categories' && (Array.isArray(value) && value.length > 0) ? value[0] : value;
  switch (name) {
    case 'include':
    case 'exclude':
    case 'client':
    case 'scenario':
    case 'categories':
      return currentValue;
    case 'client_total_debt':
    case 'client_due_debt':
      return Number(currentValue);
    case 'channel':
    case 'scenario_group':
    case 'manager':
      return [currentValue] as string[];
    default:
      return null;
  }
};

const getFilterOperator = (
  name: string,
  defaultOperator?: Misc.FilterOperators,
): OperatorFiltering | Misc.FilterOperators => {
  switch (name) {
    case 'manager':
    case 'channel':
    case 'scenario_group':
    case 'categories':
      return defaultOperator === '!=' ? 'NOT IN' : 'IN';
    case 'include':
      return 'CONTAINS';
    case 'exclude':
      return 'DOES NOT CONTAIN';
    default:
      return defaultOperator ?? '=';
  }
};

const getFilterForEAV = (
  filters: Misc.Filter[],
  attributes: EavConstructor[],
): Filter[] | null => {
  const eav: Misc.Filter[] = filters.filter(({ name }) => name === 'attributes');

  const payload: Filter[] = [];
  eav.forEach((item) => {
    if (!item.value) {
      return;
    }

    const values = Array.isArray(item.value) ? item.value : (item.value as string).split(':');
    const [eavName, eavValue] = values;
    const attributeFromStore = attributes.find((attribute) => attribute.identifier === eavName);
    let value = null;
    if (attributeFromStore) {
      switch (attributeFromStore.type) {
        case EAVType.BOOLEAN:
          value = eavValue === 'true';
          break;
        case EAVType.DATE:
          value = `${eavValue} 00:00:00`;
          break;
        case EAVType.NUMBER:
          value = Number(eavValue);
          break;
        default:
          value = eavValue;
      }
    }

    payload.push({
      field: `values.${eavName}`,
      operator: item.operator as OperatorFiltering,
      value,
      context: [],
    });
  });

  return payload;
};

const getFiltersForType = (filters: Misc.Filter[]): Filter[] => {
  const typeValues = filters.find(({ name }) => name === 'type');

  if (!typeValues) {
    return [{
      field: 'type',
      operator: 'IN',
      value: ['no-filter'],
      context: [],
    }];
  }
  const currentValues = Array.isArray(typeValues.value) ? typeValues.value : [typeValues.value];

  const allFutureSelected = [
    StepActionTypeFilter.TASK_PENDING,
    StepActionTypeFilter.REMINDER_TO_VALIDATE,
    StepActionTypeFilter.REMINDER_TO_COMPLETE,
    StepActionTypeFilter.REMINDER_AUTO,
    StepActionTypeFilter.REMINDER_PENDING,
    StepActionTypeFilter.REMINDER_VALIDATED,
  ].every((value) => currentValues.includes(value));

  const allDoneSelected = [
    StepActionTypeFilter.TASK_COMPLETED,
    StepActionTypeFilter.TASK_CANCELLED,
    StepActionTypeFilter.REMINDER_ERROR,
    StepActionTypeFilter.REMINDER_CANCELLED,
    StepActionTypeFilter.REMINDER_COMPLETED_AUTO,
    StepActionTypeFilter.REMINDER_COMPLETED_MANUAL,
  ].every((value) => currentValues.includes(value));

  if (allFutureSelected || allDoneSelected) {
    return [{
      field: 'type',
      operator: 'IN',
      value: [StepActionElasticSearchType.REMINDER, StepActionElasticSearchType.TASK],
      context: [],
    }];
  }

  const status = uniq(currentValues);

  const result: Filter[] = status.length > 0 ? [
    {
      field: 'status',
      operator: 'IN',
      value: status as string[],
      context: [],
    },
  ] : [];

  return result;
};

const getFiltersForPeriod = (filters: Misc.Filter[], actionCategory: ActionCategory): Filter | null => {
  const period = filters.find(({ name }) => name === 'period');
  if (!period) {
    return null;
  }

  const field = actionCategory === ActionCategory.FUTURE ? 'due_at' : 'completed_at';
  const currentDate = dayjs();
  const minus14Days = currentDate.subtract(14, 'day');
  const minus15Days = currentDate.subtract(15, 'day');
  const minus45Days = currentDate.subtract(45, 'day');
  const minus75Days = currentDate.subtract(75, 'day');
  const plus14Days = currentDate.add(14, 'day');
  const plus45Days = currentDate.add(45, 'day');
  switch (period.value) {
    case StepActionPeriodFilter.LAST_15_DAYS:
      return {
        field,
        operator: 'BETWEEN',
        value: [`${minus14Days.format('YYYY-MM-DD')} 00:00:00`, `${currentDate.format('YYYY-MM-DD')} 23:59:59`],
        context: [],
      };
    case StepActionPeriodFilter.LAST_15_AND_45_DAYS:
      return {
        field,
        operator: 'BETWEEN',
        value: [`${minus45Days.format('YYYY-MM-DD')} 00:00:00`, `${minus15Days.format('YYYY-MM-DD')} 23:59:59`],
        context: [],
      };
    case StepActionPeriodFilter.LAST_45_AND_75_DAYS:
      return {
        field,
        operator: 'BETWEEN',
        value: [`${minus75Days.format('YYYY-MM-DD')} 00:00:00`, `${minus45Days.format('YYYY-MM-DD')} 23:59:59`],
        context: [],
      };
    case StepActionPeriodFilter.UNTIL_TODAY:
      return {
        field,
        operator: 'BETWEEN',
        value: ['2000-01-01 00:00:00', `${currentDate.format('YYYY-MM-DD')} 23:59:59`],
        context: [],
      };
    case StepActionPeriodFilter.UPCOMING:
      return {
        field,
        operator: '>',
        value: `${currentDate.format('YYYY-MM-DD')} 23:59:59`,
        context: [],
      };
    case StepActionPeriodFilter.NEXT_15_DAYS:
      return {
        field,
        operator: 'BETWEEN',
        value: [`${currentDate.format('YYYY-MM-DD')} 23:59:59`, `${plus14Days.format('YYYY-MM-DD')} 23:59:59`],
        context: [],
      };
    case StepActionPeriodFilter.NEXT_15_AND_45_DAYS:
      return {
        field,
        operator: 'BETWEEN',
        value: [`${plus14Days.format('YYYY-MM-DD')} 23:59:59`, `${plus45Days.format('YYYY-MM-DD')} 23:59:59`],
        context: [],
      };
    case StepActionPeriodFilter.CUSTOMIZED:
      const periodsFilter = filters.find(({ name }) => name === 'periodRange');
      const periodAFilter = filters.find(({ name }) => name === 'periodA');
      const periodBFilter = filters.find(({ name }) => name === 'periodB');

      if (!periodsFilter && (!periodAFilter || !periodBFilter)) {
        return null;
      }

      let periodAValue, periodBValue;
      if (periodsFilter) {
        const periods = periodsFilter.value;
        if (!periods || periods.length < 2) {
          return null;
        }
        [periodAValue, periodBValue] = (periods as string[]).map((date) => dayjs(date as string));
      } else {
        periodAValue = dayjs(periodAFilter!.value as string);
        periodBValue = dayjs(periodBFilter!.value as string);
      }

      return {
        field,
        operator: 'BETWEEN',
        value: [`${periodAValue.format('YYYY-MM-DD')} 00:00:00`, `${periodBValue.format('YYYY-MM-DD')} 23:59:59`],
        context: [],
      };
    default:
      return null;
  }
};

export const getFilter = (
  attributes?: EavConstructor[],
  filtering?: Misc.Filter[],
): Filter[] => {
  const filterMap: { [key: string]: Filter } = {};

  if (!filtering) {
    return [];
  }

  filtering
    .filter(({ name }) => !['attributes', 'attributeValue', 'type', 'period'].includes(name))
    .forEach(({ name, operator, value }) => {
      const field = getMappingFromFormToApi(name);
      const filterValue = getFilterValue(name, value);
      const key = `${field}:${operator}`;

      // IN / NOT IN
      if (filterMap[key]) {
        if (operator === '=' && filterMap[key].operator === '=') {
          filterMap[key].operator = 'IN';
          filterMap[key].value = [filterMap[key].value as string[], filterValue as string].flat();
          return;
        } else if (operator === '!=' && filterMap[key].operator === '!=') {
          filterMap[key].operator = 'NOT IN';
          filterMap[key].value = [filterMap[key].value as string[], filterValue as string].flat();
          return;
        }
      }

      // Between
      let isBetween = filtering.filter((f) => f.name === name).length === 2;
      if (isBetween) {
        const oppositeField = Object.entries(filterMap).find(([tempKey]) =>
          tempKey.startsWith(field) && (
            (operator === '>' || operator === '>=') && (filterMap[tempKey].operator === '<' || filterMap[tempKey].operator === '<=') ||
            (operator === '<' || operator === '<=') && (filterMap[tempKey].operator === '>' || filterMap[tempKey].operator === '>=')
          ),
        );
        if (oppositeField) {
          filterMap[oppositeField[0]].operator = 'BETWEEN';
          filterMap[oppositeField[0]].value = [
            filterMap[oppositeField[0]].value as string[],
            filterValue as string,
          ].flat();
          return;
        }
      }

      filterMap[key] = {
        field,
        operator: getFilterOperator(name, operator) as OperatorFiltering,
        value: filterValue,
        context: [],
      };
    });

  const filters: Filter[] = Object.values(filterMap);

  const eavFilter = attributes ? getFilterForEAV(filtering, attributes) : null;
  if (eavFilter) {
    filters.push(...eavFilter);
  }

  return filters;
};

const transformFiltersForElasticSearchEndpoint = (filtering: Misc.Filter[] | undefined, attributes: EavConstructor[] | undefined, actionCategory: ActionCategory) => {
  const filters: Filter[] = getFilter(attributes, filtering);
  // filtre pour type
  const typeFilters = filtering ? getFiltersForType(filtering) : [];
  filters.push(...typeFilters);

  // filtre pour period
  const periodFilters = filtering ? getFiltersForPeriod(filtering, actionCategory) : null;
  if (periodFilters) {
    filters.push(periodFilters);
  }
  return filters.filter((filter) => filter.field !== null);
};

const transformSortForEndpoint = (sort: SortingRule<StepAction>, filters?: Misc.Filter[] | undefined) => {
  const period = filters?.find(({ name }) => name === 'period');
  let direction = sort.desc ? SortDirection.DESC : SortDirection.ASC;

  if (period) {
    switch (period.value) {
      case StepActionPeriodFilter.LAST_15_DAYS:
      case StepActionPeriodFilter.LAST_15_AND_45_DAYS:
      case StepActionPeriodFilter.LAST_45_AND_75_DAYS:
      case StepActionPeriodFilter.UNTIL_TODAY:
        direction = SortDirection.DESC;
        break;
      case StepActionPeriodFilter.UPCOMING:
      case StepActionPeriodFilter.NEXT_15_DAYS:
      case StepActionPeriodFilter.NEXT_15_AND_45_DAYS:
        direction = SortDirection.ASC;
        break;
      case StepActionPeriodFilter.CUSTOMIZED:
        const periodBFilter = filters?.find(({ name }) => name === 'periodB');
        if (periodBFilter) {
          const date = dayjs(periodBFilter.value as string);
          const today = dayjs();
          direction = date.isAfter(today) ? SortDirection.ASC : SortDirection.DESC;
        }
        break;
    }
  }

  return { field: sort.id, direction };
};

const allFuture = async (id: Organization['id'] | undefined, params: FetchActionsFutureAllParams | undefined): Promise<SearchResult<StepAction>> => {
  const { page = 0, filtering, locale, organizationReference, pageSize, attributes, sort } = params ?? { page: 0, filtering: undefined };

  if (!organizationReference) {
    throw new Error('FetchAllCustomers: Missing organization reference.');
  }

  const filters: Filter[] = transformFiltersForElasticSearchEndpoint(filtering, attributes, ActionCategory.FUTURE);

  const { data } = await requester.put<SearchResult<StepAction>>(
    'step_actions',
    {
      channel: organizationReference,
      locale,
      filters,
      page,
      size: pageSize,
      sort: sort ? transformSortForEndpoint(sort, filtering) : undefined,
    },
  );

  return data;
};

const allDone = async (
  id: Organization['id'] | undefined,
  params: FetchActionsDoneAllParams | undefined,
): Promise<SearchResult<HistoryStepActionbWebHook>> => {
  const {
    page = 0,
    filtering,
    locale,
    organizationReference,
    pageSize,
    attributes,
  } = params ?? { page: 0 };

  if (!organizationReference) {
    throw new Error('FetchAllCustomers: Missing organization reference.');
  }

  const filters: Filter[] = transformFiltersForElasticSearchEndpoint(filtering, attributes, ActionCategory.DONE);

  const { data } = await requester.put<SearchResult<StepAction>>(
    'step_action_histories',
    {
      channel: organizationReference,
      locale,
      filters,
      page,
      size: pageSize,
      sort: {
        field: 'completed_at',
        direction: 'desc',
      },
    },
  );

  return data;
};

/**
 * Récupère les données d'une action réalisée.
 *
 * @param code Le code de la step action
 * @returns URL du post.
 */
const fetchHistoryContent = async (code: StepAction['code'] | null) => {
  if (!code) {
    throw new Error('FetchHistoryContent: Missing code.');
  }

  const { data } = await requester.post<HistoryStepPreviewContent[]>(
    `step_action_histories/${code}/preview`,
    {},
  );
  return data;
};

/**
 * Récupère contenu de relance/tâche avec des valeurs de tags converties.
 *
 * @param code Le code de la step action
 * @returns URL du post.
 */
const fetchConvertContent = async (code: StepAction['code'] | null) => {
  if (!code) {
    throw new Error('FetchHistoryContent: Missing code.');
  }

  const { data } = await requester.post<HistoryStepConvertContent>(
    `step_actions/${code}/convert`,
    {},
  );
  return data;
};

/**
 * Récupère les données d'une action prévue.
 *
 * @param code Le uuid de la step action
 * @returns URL du post.
 */
const fetchPreviewContent = async ({ code, description, name, subject, content }: FetchPreviewParams) => {
  if (!code) {
    throw new Error('FetchPreviewContentHistoryStep: Missing code.');
  }

  const { data } = await requester.post<HistoryStepPreviewContent[]>(
    `step_actions/${code}/preview`,
    {
      name: name ?? undefined,
      description: description ?? undefined,
      subject: subject ?? undefined,
      content: content ?? undefined,
    },
  );

  return data;
};

const bulkUpdateUrl = 'step_actions';

/**
 * Récupère l'URL de la ressource API pour modifier le step action.
 *
 * @param id Le code (uuid) de la step action
 * @returns URL du PATCH.
 */
const updateUrl = (id: StepAction['code']) => `step_actions/${id}`;

/**
 * Récupère l'URL de la ressource API pour l'envoi immédiat.
 *
 * @param id Le code (uuid) de la step action
 * @returns URL du POST.
 */
const sendReminder = (id: StepAction['code']) => `step_actions/${id}/send`;

const createReminderUrl = 'step_actions/reminder';

const createTaskUrl = 'step_actions/task';

/**
 * l'export des actions prévues.
 *
 * @param id Id de l'organization.
 * @param params Paramètres de la requête
 * @returns csv.
 */
const exportFutureUrl = async (params: FetchActionsFutureAllParams | undefined) => {
  const { filtering, locale, organizationReference, attributes, sort } = params ?? { filtering: undefined };

  if (!organizationReference) {
    throw new Error('FutureActionsExport: Missing organization reference.');
  }

  const filters: Filter[] = transformFiltersForElasticSearchEndpoint(filtering, attributes, ActionCategory.FUTURE);

  const result = await requester.post(
    'step_actions/export',
    {
      channel: organizationReference,
      locale,
      filters,
      sort: sort ? transformSortForEndpoint(sort) : undefined,
    },
  );
  return result;
};

/**
 * l'export des actions réalisées.
 *
 * @param id Id de l'organization.
 * @param params Paramètres de la requête
 * @returns csv.
 */
const exportDoneUrl = async (params: FetchActionsFutureAllParams | undefined) => {
  const { filtering, locale, organizationReference, attributes } = params ?? { filtering: undefined };

  if (!organizationReference) {
    throw new Error('DoneActionsExport: Missing organization reference.');
  }

  const filters: Filter[] = transformFiltersForElasticSearchEndpoint(filtering, attributes, ActionCategory.DONE);

  const result = await requester.post(
    'step_action_histories/export',
    {
      channel: organizationReference,
      locale,
      filters,
      sort: {
        field: 'completed_at',
        direction: 'desc',
      },
    },
  );
  return result;
};

export default {
  allFuture,
  allDone,
  allHistoryForCustomer,
  allHistoryForDebit,
  allPastStepsForDebit,
  allFutureStepsForDebit,
  allFutureStepsForCustomer,
  allPastStepsForCustomer,
  fetchConvertContent,
  fetchPreviewContent,
  fetchHistoryContent,
  bulkUpdateUrl,
  updateUrl,
  createReminderUrl,
  createTaskUrl,
  exportFutureUrl,
  exportDoneUrl,
  sendReminder,
  transformFiltersForElasticSearchEndpoint,
};
