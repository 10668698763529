import './index.scss';
import { useCallback, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useModal } from 'react-modal-hook';
import { useTranslation } from 'react-i18next';
import equal from 'deep-equal';
import apiScenarios from 'api/scenarios';
import type { ActionPost, DataBlock, Scenario } from 'types/models';
import { Channel, ScenarioFrequency, StepActionEntity } from 'types/models';
import type { ValidationErrors } from 'types/errors';
import userRightsStore from 'stores/UserRights';
import getI18nChannel from 'utils/getI18nChannel';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import { checkIsAllowed } from 'components/UserRights';
import useIsMountedRef from 'hooks/useIsMountedRef';
import useApiRequest from 'hooks/useApiRequest';
import organizationStore from 'stores/Organization';
import Confirm from 'components/Confirm';
import ModalTemplateBlocks from 'components/ModalTemplateBlocks';
import StepActionIcon from 'components/StepActionIcon';
import Button from 'components/Button';
import { RightsCategory } from 'types/models';
import ReminderForm from './Form';

type Props = {
  index?: number,
  action: ActionPost | null,
  onActionDone(message: string): void,
  onActionError(message: string): void,
  onClose(): void,
  onDelete?(): void,
  channel: Channel,
  edit?: boolean,
  scenario: Scenario,
};

const ScenarioModalAddAction = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const isMountedRef = useIsMountedRef();
  const {
    index = 0,
    action,
    onActionDone,
    onActionError,
    channel,
    onClose,
    onDelete,
    edit = false,
    scenario,
  } = props;
  const { currentOrganization } = organizationStore;
  const { rights } = userRightsStore;

  const { put } = useApiRequest();

  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors | null>(null);
  const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);
  const [model, setModel] = useState<DataBlock | undefined>();

  const roleDefaultValue = useMemo<string[]>(() => (
    action ? action.contacts?.map((contactRole) => (contactRole)) || [] : []
  ), [action]);

  const initialData = useRef<ActionPost>({
    day: action?.day || 0,
    name: action?.name || '',
    description: action?.description || '',
    subject: action?.subject || '',
    content: action?.content || '',
    contacts: roleDefaultValue,
    withValidation: action?.withValidation || false,
    type: 'REMINDER',
    repeat: {
      day: null,
      frequency: null,
    },
  });

  const mapFormData = useCallback(
    (rawData: ModalFormData): ActionPost | null => {
      if (!currentOrganization) {
        return null;
      }

      return {
        day: parseInt(rawData.day as string, 10),
        name: rawData.name as string,
        description: rawData.description as string,
        subject: action?.channel === Channel.EMAIL ? rawData.subject as string : '',
        content: rawData.content as string,
        contacts: (rawData.roles as string).split(','),
        withValidation: rawData.withValidation === '1',
        type: 'REMINDER',
        repeat: {
          day: parseInt(rawData.interval as string, 10),
          frequency: parseInt(rawData.frequency as string, 10),
        },
      };
    },
    [action, currentOrganization],
  );

  const [showModalTemplateBlocks, hideModalTemplateBlocks] = useModal(() => (
    <ModalTemplateBlocks
      type="reminder"
      onClose={hideModalTemplateBlocks}
      onSelect={setModel}
      channel={channel}
    />
  ), [channel]);

  const closeSelf = useCallback(() => {
    if (!isMountedRef.current) {
      return;
    }

    setShowCancelConfirm(false);
    setHasChanges(false);
    setValidationErrors(null);
    onClose();
  }, [isMountedRef, onClose]);

  const handleCancel = useCallback(
    () => {
      if (hasChanges) {
        setShowCancelConfirm(true);
      } else {
        closeSelf();
      }
    },
    [hasChanges, closeSelf],
  );

  const handleChange = useCallback(
    (formData: ModalFormData | null) => {
      setHasChanges(
        !!formData && !equal(initialData.current, mapFormData(formData)),
      );
    },
    [initialData, mapFormData],
  );

  const handleSubmitCreateOrEdit = useCallback(async (formData: ModalFormData | null) => {
    if (!currentOrganization || (!action && edit) || !formData) {
      return;
    }

    const roles = formData.roles as string;
    const title = formData.name as string;

    if (!title) {
      setValidationErrors({ name: { code: 2, message: t('errors:validation.required-field') } });
      return;
    }

    let day = parseInt(formData.days as string, 10);
    const date: string = formData.date as string;
    day = date.startsWith('before') ? -day : day;
    const body = {
      name: formData.name as string,
      day: (day),
      channel,
      description: formData.description as string,
      withValidation: formData.withValidation === '1',
      contacts: roles.split(','),
      subject: formData.subject as string,
      content: formData.content as string,
      code: edit ? scenario.reminders[index].code : undefined,
      hash: edit ? scenario.reminders[index].hash : undefined,
      repeat: formData.repeat !== ScenarioFrequency.NEVER ? {
        day: parseInt(formData.interval as string, 10),
        frequency: formData.repeat === ScenarioFrequency.UNLIMITED ? 0 : parseInt(formData.frequency as string, 10),
      } : null,
    };

    const payload = { reminders: apiScenarios.buildPayloadListData(scenario.reminders, index, edit ? 'edit' : 'create', body) };
    const result = await put(apiScenarios.updateUrl(scenario.id), payload);
    if (!result) {
      onActionError(edit ? t('plans:toast.error.edited-action') : t('plans:toast.error.added-action'));
      return;
    }
    onClose();
    onActionDone(t(edit ? 'plans:toast.success.edited-action' : 'plans:toast.success.added-action', { name: action?.name }));
  }, [currentOrganization, action, edit, channel, scenario.reminders, scenario.id, index, put, onClose, onActionDone, t, onActionError]);

  const modalTitle = useMemo(() => {
    if (edit) {
      return t('plans:edit-action', { channel: t(getI18nChannel(channel)) });
    }
    return t('plans:add-reminder', { channel: t(getI18nChannel(channel)) });
  }, [channel, edit, t]);

  const handleSubmitDelete = useCallback(() => {
    if (!onDelete) {
      return;
    }

    onDelete();
    closeSelf();
  }, [onDelete, closeSelf]);

  return (
    <ModalForm
      isOpened
      className="ScenarioModalAddAction"
      title={modalTitle}
      subTitle={action?.name ?? ''}
      icon={<StepActionIcon entity={StepActionEntity.REMINDER} channel={channel} />}
      buttonsDisabled={rights === null || !checkIsAllowed('UPDATE', RightsCategory.PREFERENCES_PLANS)}
      onChange={handleChange}
      hasWarning={hasChanges}
      onSave={handleSubmitCreateOrEdit}
      onCancel={handleCancel}
      isLoading={false}
      hasDeleteButton={edit}
      onDelete={handleSubmitDelete}
      headerActions={(
        <Button variant="link" onClick={showModalTemplateBlocks}>{t('models:import')}</Button>
      )}
    >
      <ReminderForm
        scenarioId={scenario.id}
        defaultData={model ? { ...model, day: 0, type: 'REMINDER' } : action}
        errors={validationErrors}
        dateReference={scenario.dateReferenceIsToday}
        channel={channel}
      />
      <Confirm
        titleModal={t('common:confirm-cancel-form')}
        text={t('common:confirm-loose-all-modifications')}
        variant="danger"
        confirmButtonText={t('common:close-form')}
        cancelButtonText={t('common:stay-on-form')}
        isShow={showCancelConfirm}
        onConfirm={closeSelf}
        onCancel={() => { setShowCancelConfirm(false); }}
      />
    </ModalForm>
  );
};

export default observer(ScenarioModalAddAction);
