import './index.scss';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import SelectSearch from 'react-select-search';
import type { SelectSearchOption } from 'react-select-search';
import Button from 'components/Button';
import Avatar from 'components/Avatar';
import type { Locale, User } from 'types/models';
import apiLocales from 'api/locales';
import apiUserProfile from 'api/userProfile';
import authStore from 'stores/Auth';
import type { ToastStatus } from 'components/ToastNotification/useToast';
import FormControl from 'components/FormControl';
import Loading from 'components/Loading';
import SettingsList from '../../List';
import ModalEditPassword from './ModalEditPassword';

type Props = {
  user: User,
  isBusy: boolean,
  onShowToast(message: string, type: ToastStatus): void,
};

const SettingsProfileMyProfile = ({ user, isBusy, onShowToast }: Props): JSX.Element => {
  const { t } = useTranslation();
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const [isSavingLocale, setIsSavingLocale] = useState<boolean>(false);
  const [isSavingAvatar, setIsSavingAvatar] = useState<boolean>(false);

  const [showModalEditPassword, hideModalEditPassword] = useModal(() => (
    <ModalEditPassword
      onClose={hideModalEditPassword}
      onShowToast={onShowToast}
    />
  ), [onShowToast]);

  const handleUploadAvatar = useCallback(() => {
    if (inputFileRef?.current) {
      inputFileRef.current.click();
    }
  }, []);

  const handleAvatarChange = useCallback(async (newAvatar: File) => {
    setIsSavingAvatar(true);

    try {
      const formDataFile = new FormData();
      formDataFile.append('avatar', newAvatar);

      const response = await apiUserProfile.setAvatar(user.id, formDataFile);
      if (!response) {
        onShowToast(t('users:toast.error.settings-update'), 'error');
        return;
      }

      authStore.fetchUser();
      onShowToast(t('users:toast.success.settings-update'), 'success');
    } catch {
      onShowToast(t('users:toast.error.settings-update'), 'error');
    } finally {
      setIsSavingAvatar(false);
    }
  }, [user, onShowToast, t]);

  const locales = useMemo(async (): Promise<Locale[]> => {
    try {
      const data = await apiLocales.getAll();
      return data;
    } catch {
      return [];
    }
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getLocalesOptions = useCallback(async (query: string): Promise<SelectSearchOption[]> => (
    (await locales)
      .map(({ name, code }: Locale): SelectSearchOption => ({
        name,
        value: code,
      }))
  ), [locales]);

  const handleChangeLanguage = useCallback(async (newLanguage: string | string[]) => {
    setIsSavingLocale(true);

    try {
      const locale = (await locales).find(({ code }: Locale) => newLanguage === code);
      if (!locale) {
        onShowToast(t('users:toast.error.settings-update'), 'error');
        return;
      }

      const response = await apiUserProfile.setLanguage(user.id, locale.id);
      if (!response) {
        onShowToast(t('users:toast.error.settings-update'), 'error');
        return;
      }

      authStore.fetchUser();
      onShowToast(t('users:toast.success.settings-update'), 'success');
    } catch {
      onShowToast(t('users:toast.error.settings-update'), 'error');
    } finally {
      setIsSavingLocale(false);
    }
  }, [locales, user, onShowToast, t]);

  const listData = useMemo(() => {
    const { email, phone, locale } = user;

    return [
      {
        label: t('common:phone'),
        value: phone || '-',
      },
      {
        label: t('common:email'),
        value: email,
      },
      {
        label: t('common:password'),
        value: (
          <Button onClick={showModalEditPassword} variant="link">
            {t('users:edit-password')}
          </Button>),
      },
      {
        label: t('users:prefered-language'),
        value: (
          <SelectSearch
            onChange={handleChangeLanguage}
            value={locale}
            getOptions={getLocalesOptions}
            options={[]}
          />
        ),
        isLoading: (isSavingLocale || isBusy),
      },
    ];
  }, [user, t, showModalEditPassword, handleChangeLanguage, getLocalesOptions, isSavingLocale, isBusy]);

  const {
    avatar,
    firstName,
    lastName,
    email,
  } = user;

  return (
    <div className="SettingsProfileMyProfile">
      <div className="SettingsProfileMyProfile__header">
        <Button
          variant="outline"
          onClick={handleUploadAvatar}
          className="SettingsProfileMyProfile__header__image"
        >
          <div className="SettingsProfileMyProfile__header__image__text">
            {t('common:edit')}
          </div>
          <FormControl
            ref={inputFileRef}
            onFileChange={handleAvatarChange}
            type="file"
            name="avatar"
            className="SettingsProfileMyProfile__header__image__form"
          />
          {isSavingAvatar && (<Loading hasNoText />)}
          {!isSavingAvatar && (
            <Avatar
              username={`${firstName} ${lastName}`}
              userAvatar={avatar}
              size={128}
            />
          )}
        </Button>
        <h3 className="SettingsProfileMyProfile__header__name">
          {(`${firstName} ${lastName}`) || email}
        </h3>
      </div>
      <SettingsList data={listData} />
    </div>
  );
};

export default SettingsProfileMyProfile;
