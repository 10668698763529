import './index.scss';
import classnames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import { Link } from 'react-router-dom';
import { HistoryActivityType } from 'types/models';
import type { HistoryActivity } from 'types/models';
import dateIsLastTwoDays from 'utils/dateIsLastTwoDays';
import organizationStore from 'stores/Organization';
import Badge from 'components/Badge';
import CreditViewModal from 'components/CreditViewModal';
import CreditEditModal from 'components/CreditEditModal';
import Button from 'components/Button';
import formatIntlNumber from 'utils/formatIntlNumber';

type Props = {
  data: HistoryActivity,
  standalone?: boolean,
  onActionDone(message: string): void,
  onActionError?(message: string): void,
};

const HistoryActivityInfosHeader = (props: Props): JSX.Element => {
  const { data, onActionDone, standalone = false } = props;
  const { translation, content, type, options, timestamp } = data;
  const { t } = useTranslation();
  const { currency } = organizationStore.currentOrganization!;
  const isRecent = useMemo(() => dateIsLastTwoDays(timestamp), [timestamp]);
  const [title, setTitle] = useState<string>();
  const [group, setGroup] = useState<string>();

  useEffect(() => {
    if (!translation) {
      return;
    }

    const regex: RegExp = /@([^@]+)@/;
    const match: RegExpMatchArray | null = translation.match(regex);
    setGroup(match ? match[1] : '');
    setTitle(translation.replace(regex, ''));
  }, [translation]);

  const [showCreditEditModal, hideCreditEditModal] = useModal(() => (
    options ? (
      <CreditEditModal
        onClose={hideCreditEditModal}
        onDone={() => {
          hideCreditEditModal();
          onActionDone(
            t('credits:toast.modified-reference', { reference: options.reference }),
          );
        }}
        creditId={options.id}
      />
    ) : null
  ));

  const [showCreditViewModal, hideCreditViewModal] = useModal(() => (
    options ? (
      <CreditViewModal
        onClose={hideCreditViewModal}
        onClickEdit={() => {
          hideCreditViewModal();
          showCreditEditModal();
        }}
        creditId={options.id}
      />
    ) : null
  ));

  const hasBadge = useMemo(() => (
    [
      HistoryActivityType.NOTE,
      HistoryActivityType.NOTE_HISTORY,
      HistoryActivityType.CREDIT,
      HistoryActivityType.DEBIT,
    ].includes(type)
  ), [type]);

  const detailsClassNames = classnames('HistoryActivityInfosHeader__details', {
    'HistoryActivityInfosHeader__details--note': type === HistoryActivityType.NOTE,
  });

  return (
    <div className="HistoryActivityInfosHeader">
      <div className="HistoryActivityInfosHeader__titles">
        <span className="HistoryActivityInfosHeader__titles__text">
        {
          title ??
            (type === HistoryActivityType.NOTE || type === HistoryActivityType.NOTE_HISTORY ?
              t('notes:create-history') : '')
        }
        </span>
      </div>
      {!standalone &&
        <div className={detailsClassNames}>
          {hasBadge && (
            <Badge variant="NOTE" isRecent={isRecent}>
              {[
                HistoryActivityType.NOTE,
                HistoryActivityType.NOTE_HISTORY,
              ].includes(type) && t('common:note')}
              {type === HistoryActivityType.DEBIT && t('common:debit')}
              {type === HistoryActivityType.CREDIT && t('common:credit')}
            </Badge>
          )}
          {(!hasBadge && isRecent) && (
            <Badge variant="NOTE" isRecent>{t('common:recent')}</Badge>
          )}
          {group && (
            <Badge variant="CUSTOM">{group}</Badge>
          )}
          {(type === HistoryActivityType.DEBIT) && (
            <Link
              to={`/debit/${options?.id}`}
              className="HistoryActivityInfosHeader__details__debit"
            >
              {options?.reference}
            </Link>
          )}
          {(type === HistoryActivityType.CREDIT) && (
            <Button
              variant='link'
              className="HistoryActivityInfosHeader__details__link"
              onClick={showCreditViewModal}
            >
              {t('credits:payment-assigned')} {formatIntlNumber(parseFloat(options?.amount), currency)} {t('common:via')} {options.reference}
            </Button>
          )}
          <p className="HistoryActivityInfosHeader__details__note-content">
            {content ?? options?.comment}
          </p>
        </div>
      }
    </div>
  );
};

export default HistoryActivityInfosHeader;
