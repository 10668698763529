import './index.scss';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useModal } from 'react-modal-hook';
import type { ValidationErrors } from 'types/errors';
import type { ActionPost } from 'types/models';
import { Channel } from 'types/models';
import { ScenarioDateReference } from 'types/models';
import type Misc from 'types/misc';
import organizationStore from 'stores/Organization';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import FormSelect from 'components/FormSelect';
import FormGroupWithActions from 'components/FormGroupWithActions';
import TagLink from 'components/TagLink';
import Wysiwyg from 'components/Wysiwyg';
import FormControlSMSContent from 'components/FormControlSMSContent';
import getI18nChannel from 'utils/getI18nChannel';
import getContactRole from 'utils/getContactRole';
import Button from 'components/Button';
import FormCheck from 'components/FormCheck';
import ScenarioStepContentPreview from 'components/ScenarioStepContentPreview';
import getComparableString from '../../utils';
import ActionRepeatFieldset from '../../ActionRepeatFieldset';

type Props = {
  defaultData?: ActionPost | null,
  errors?: ValidationErrors | null,
  dateReference: ScenarioDateReference,
  channel: Channel,
  scenarioId: number,
};

const ScenarioModalAddActionForm = (props: Props): JSX.Element => {
  const { defaultData, errors, channel, dateReference, scenarioId } = props;
  const { t } = useTranslation();
  const { contactRoles } = organizationStore.currentOrganization!;
  const [name, setName] = useState<string>(defaultData?.name || '');
  const [description, setDescription] = useState<string>(defaultData?.description || '');
  const [subject, setSubject] = useState<string>(defaultData?.subject || '');
  const [content, setContent] = useState<string>(defaultData?.content || '');
  const oldDefaultData = useRef(getComparableString(defaultData));

  useEffect(() => {
    if (!defaultData || oldDefaultData.current === getComparableString(defaultData)) {
      return;
    }
    oldDefaultData.current = getComparableString(defaultData);
    setName(defaultData.name || '');
    setDescription(defaultData.description || '');
    setSubject(defaultData.subject || '');
    setContent(defaultData.content || '');
  }, [defaultData]);

  const roleDefaultValue = useMemo<Misc.Identifier[]>(() => {
    if (!defaultData || !contactRoles) {
      return [];
    }

    return defaultData.contacts?.map((identifier) => ({
      label: getContactRole(identifier, contactRoles),
      identifier,
      value: contactRoles,
    })) || [];
  }, [defaultData, contactRoles]);

  const [showPreviewContentModal, hidePreviewContentModal] = useModal(() => (
    <ScenarioStepContentPreview
      scenarioId={scenarioId}
      content={content}
      channel={defaultData?.channel || Channel.EMAIL}
      onClose={hidePreviewContentModal}
    />
  ), [defaultData, content, scenarioId]);

  return (
    <div className="ScenarioModalAddActionForm">
      <div className="ScenarioModalAddActionForm__main">
        <FormFieldset>
          <FormGroup label={t('plans:planned')} error={errors?.startAt}>
            <div className="ScenarioModalAddActionForm__days">
              <FormControl
                type="number"
                min={0}
                name="days"
                defaultValue={
                  defaultData?.day !== undefined
                    ? Math.abs(defaultData?.day)
                    : undefined
                }
                isInvalid={!!errors?.startAt}
              />
              <FormSelect
                name="date"
                selectOptions={[
                  { label: t('plans:after-reference-date'), value: `after-${dateReference}` },
                  { label: t('plans:before-reference-date'), value: `before-${dateReference}` },
                ]}
                withClearButton={false}
                defaultValue={defaultData && defaultData?.day < 0 ? `before-${dateReference}` : `after-${dateReference}`}
                isInvalid={!!errors?.date}
              />
            </div>
          </FormGroup>
          <FormGroup label={t('reminders:short-description')} mandatory error={errors?.name}>
            <FormControl
              type="text"
              name="name"
              autoComplete="off"
              value={name}
              placeholder={t('reminders:placeholder.short-description')}
              isInvalid={!!errors?.name}
              onChange={setName}
            />
          </FormGroup>
        </FormFieldset>
        <FormFieldset>
          <FormGroup label={t('plans:detailed-description')} error={errors?.description}>
            <FormControl
              type="text"
              name="description"
              autoComplete="off"
              placeholder={t(
                'reminders:placeholder.description',
                { channel: t(getI18nChannel(defaultData?.channel || Channel.SMS)) },
              )}
              value={description}
              onChange={setDescription}
              isInvalid={!!errors?.description}
            />
          </FormGroup>
        </FormFieldset>
        <FormFieldset>
          <ActionRepeatFieldset
            defaultData={defaultData}
            errors={errors}
          />
          <FormGroup
            label={t('plans:need-validation')}
            className="ScenarioModalAddActionForm__need-validation"
            error={errors?.withValidation}
          >
            <FormCheck
              defaultChecked={defaultData?.withValidation || false}
              name="withValidation"
              value="1"
            />
          </FormGroup>
        </FormFieldset>
      </div>
      <FormFieldset>
        <FormGroup label={t('reminders:recipients')} mandatory error={errors?.roles}>
          <FormSelect
            name="roles"
            placeholder={t('common:please-choose')}
            selectOptions={contactRoles?.map(
              ({ identifier, value }) => ({ label: value, value: identifier }),
            )}
            defaultValue={roleDefaultValue}
            isInvalid={!!errors?.roles}
            isMultiple
            isAsync
          />
        </FormGroup>
      </FormFieldset>
      {(defaultData?.subject || channel === Channel.EMAIL) && (
        <FormFieldset>
          <FormGroup
            label={channel === Channel.EMAIL ? t('reminders:email-subject') : t('reminders:subject')}
            error={errors?.subject}
          >
            <FormControl
              type="text"
              name="subject"
              autoComplete="off"
              value={subject}
              onChange={setSubject}
              isInvalid={!!errors?.subject}
              placeholder={t('reminders:placeholder.subject')}
            />
          </FormGroup>
        </FormFieldset>
      )}
      <FormFieldset>
        <FormGroupWithActions
          label={channel === Channel.EMAIL ? t('reminders:email-content') : t('reminders:content')}
          mandatory
          action={<TagLink />}
          secondaryAction={(
            <Button
              variant="link"
              onClick={showPreviewContentModal}
            >
              {t('models:preview')}
            </Button>
          )}
        >
          {channel !== Channel.SMS && (
            <Wysiwyg
              name="content"
              defaultValue={content}
              toBePrinted={channel !== Channel.EMAIL}
              onChange={setContent}
            />
          )}
          {channel === Channel.SMS && (
            <FormControlSMSContent
              name="content"
              value={content}
              onChange={setContent}
            />
          )}
        </FormGroupWithActions>
      </FormFieldset>
    </div>
  );
};

export default observer(ScenarioModalAddActionForm);
