import './index.scss';
import type { TFunction } from 'i18next';
import type { Column } from 'react-table';
import type { ScenarioGroup, Selector, SelectorCondition } from 'types/models';
import dateFormatIso from 'utils/dateFormatIso';
import { SelectorsRefDateEAV, SelectorsRefDate } from 'types/models';
import ConditionSentence from '../ConditionSentence';
import SelectorsRowActions from './RowActions';
import { getEavLabel, getReferenceDate } from './utils';

const SelectorColumns = (
  t: TFunction,
  onActionError: (message: string) => void,
  onActionDone: (message: string) => void,
  group?: ScenarioGroup,
): Column<Selector>[] => [
  {
    Header: t<string>('selectors:name'),
    accessor: 'label',
    disableSortBy: true,
    Cell: ({ value }) => <h4 className="SelectorColumn__title">{value.toLowerCase()}</h4>,
  },
  {
    Header: '',
    accessor: 'sortOrder',
    disableSortBy: true,
    Cell: ({ value }) => <p className="SelectorColumn__title">{value}</p>,
  },
  {
    Header: t<string>('selectors:conditions'),
    accessor: 'conditions',
    disableSortBy: true,
    Cell: ({ value: conditions }: { value: SelectorCondition[] }) => (
      <div>
        {!conditions && t<string>('common:unknown')}
        {conditions && conditions.map(({ condition, operator, attribute, value: thirdField }, index) => (
          <div key={`${attribute}-${operator}-${thirdField}`}>
            <p className="SelectorColumn__values-title">
              <ConditionSentence condition={{ firstField: null, operator, secondField: attribute, thirdField }}/>
            </p>
            {(!!condition && index < conditions.length - 1) && (
              <p className="SelectorColumn__values-operator">
                {condition === 'AND'
                  ? t<string>('selectors:operators.and')
                  : t<string>('selectors:operators.or')}
              </p>
            )}
          </div>
        ))}
      </div>
    ),
  },
  {
    Header: t<string>('common:scenario'),
    accessor: 'dunningScenario',
    disableSortBy: true,
    Cell: ({ value, row }) => {
      const { attribute, date, eav } = getReferenceDate(row.original.referenceDateOrigin);

      let attributeText;
      if (SelectorsRefDateEAV.hasOwnProperty(attribute)) {
        attributeText = SelectorsRefDateEAV[attribute as keyof typeof SelectorsRefDateEAV];
      } else {
        attributeText = SelectorsRefDate[attribute as keyof typeof SelectorsRefDate];
      }

      const additionalInfo = date ? `(${dateFormatIso(date)})` : eav ? getEavLabel(eav) : '';

      return <div>
        {value.label} ({value.identifier})<br />
        {attributeText && `${t('plans:reference')} : ${t(`selectors:selector-types.${attributeText}`)} `}
        {additionalInfo ?? ''}
      </div>;
    },
  },
  {
    Header: t<string>('common:actions'),
    accessor: 'id',
    disableSortBy: true,
    Cell: ({ row }) => (
      <SelectorsRowActions
        defaultData={row.original}
        id={row.original.label}
        group={group}
        onActionDone={onActionDone}
        onActionError={onActionError}
      />
    ),
  },
];

export default SelectorColumns;
